import HeadingSmall from "./HeadingSmall";
import PropTypes from "prop-types";
import React from "react";

function DashHeading({ heading }) {
  return <HeadingSmall uppercase>{`${heading} –`}</HeadingSmall>;
}

export default DashHeading;

DashHeading.propTypes = {
  heading: PropTypes.string.isRequired,
};
