import { color, space, typography } from "styled-system";

import React from "react";
import styled from "styled-components";

const RichTextBase = styled.div`
  ${color}
  ${typography}
  ${space}
  h2 {
    line-height: 26px;
    font-size: 16px;
  }
  & p {
    line-height: 26px;
    font-size: 16px;
  }
  & p:not(:first-child) {
    margin-top: 30px;
  }
  & h2:not(:first-child) {
    margin-top: 40px;
  }
  & a {
    text-decoration: underline;
    transition: opacity 300ms;
    opacity: 1;
    &:hover {
      opacity: 0.5;
    }
  }
  @media (min-width: 960px) {
    h2 {
      line-height: 42px;
      font-size: 32px;
    }
    & p {
      line-height: 32px;
      font-size: 22px;
    }
    & p:not(:first-child) {
      margin-top: 40px;
    }
    & h2:not(:first-child) {
      margin-top: 80px;
    }
  }
`;

function RichTextLarge({ children, ...props }) {
  return (
    <RichTextBase
      {...props}
      dangerouslySetInnerHTML={{ __html: children }}
    ></RichTextBase>
  );
}
export default RichTextLarge;
