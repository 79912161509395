import BrandLink from "./BrandLink";
import PropTypes from "prop-types";
import React from "react";
import ReskinnedSwiper from "./ReskinnedSwiper";

function BrandSwiper({ heading, brands }) {
  const slidesPerView = 4.1;

  return (
    <ReskinnedSwiper
      heading={heading}
      items={brands.map((brand) => {
        return <BrandLink key={brand.slug} brand={brand} />;
      })}
      slidesPerView={slidesPerView}
      extraVerticalSpace
    />
  );
}

export default BrandSwiper;

BrandSwiper.propTypes = {
  heading: PropTypes.string.isRequired,
  brands: PropTypes.array.isRequired,
};
