import Box from "./Box";
import React from "react";

function TwoColBox({ col1, col2 }) {
  return (
    <Box
      display="grid"
      gridTemplateColumns={["1fr", null, "1fr 1fr", null]}
      gridColumnGap="5.95%"
      gridRowGap={38}
      maxWidth={[null, "80vw", null, null]}
      mx="auto"
    >
      <Box>{col1}</Box>
      <Box>{col2}</Box>
    </Box>
  );
}

export default TwoColBox;
