import PropTypes from "prop-types";
import React from "react";
import Text from "./Text";

function IndentedParagraphMedium({ fontWeight, uppercase, color, children }) {
  return (
    <Text
      as="h4"
      fontWeight={fontWeight}
      uppercase={uppercase}
      fontSize={[22, 22, 32, 32]}
      lineHeight={["32px", "32px", "42px", "42px"]}
      color={color}
    >
      <span style={{ paddingRight: 60 }} />
      <span
        className="indented-paragraph"
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </Text>
  );
}

export default IndentedParagraphMedium;

IndentedParagraphMedium.defaultProps = {
  color: "inherit",
};

IndentedParagraphMedium.propTypes = {
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  uppercase: PropTypes.bool,
};
